@import "../../../data/styles.css";

/* Container for the entire works section */
.works-container {
    margin-bottom: 40px;
}

/* Header styling */
.works-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.works-header svg {
    margin-right: 10px;
}

/* Container for each work item */
.work-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd; /* Add a separator between work items */
}

/* Company logo styling */
.work-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: contain; /* Ensures the image fits properly inside the circle */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Work details container */
.work-details {
    flex-grow: 1;
}

/* Company name styling */
.work-company {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 5px;
}

/* Styling for individual job role */
.work-role {
    margin-bottom: 10px;
}

/* Job title styling */
.role-title {
    font-size: 1em;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

/* Role duration styling */
.role-duration {
    font-size: 0.9em;
    color: #888;
}

/* Role location */
.role-location {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 5px;
}

/* Role details/description */
.role-details {
    font-size: 0.9em;
    color: #444;
    line-height: 1.5;
    margin-top: 5px;
}

/* Responsive styling */
@media (max-width: 768px) {
    .work-item {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }
    .work-image {
        margin-bottom: 10px;
    }
}
